import React, { useEffect } from 'react'
import { loadDatadogRum } from './datadogLoader'
import getConfig from 'next/config'

const DatadogRum = () => {
  const {
    NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID: appId,
    NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN: clientToken,
    NEXT_PUBLIC_DATADOG_RUM_ENV: env,
    NEXT_PUBLIC_APP_VERSION: appVersion,
  } = getConfig().publicRuntimeConfig
  const DATADOG_RUM_SERVICE = {
    DATADOG_SITE: 'datadoghq.com',
    DATADOG_SERVICE: 'fdr-website',
  }
  useEffect(() => {
    const initializeDatadogRum = async () => {
      if (!appId || !clientToken || !env) return
      const datadogRum = await loadDatadogRum()
      datadogRum?.init({
        applicationId: appId,
        clientToken,
        env,
        site: DATADOG_RUM_SERVICE.DATADOG_SITE,
        service: DATADOG_RUM_SERVICE.DATADOG_SERVICE,
        version: appVersion || '0.0.0',
        trackInteractions: true,
      })
      datadogRum.startSessionReplayRecording()

      return () => {
        datadogRum.stopSessionReplayRecording()
      }
    }

    initializeDatadogRum()
  }, [
    DATADOG_RUM_SERVICE.DATADOG_SERVICE,
    DATADOG_RUM_SERVICE.DATADOG_SITE,
    appId,
    appVersion,
    clientToken,
    env,
  ])

  return null
}

export default DatadogRum
